import React, { useEffect, useState } from 'react';
import './style.scss';
import InfoTitle from '../../atoms/info_title';
import CovidRegion from '../../molecules/covid_region';
import { Row } from 'antd';
import CovidApi from '../../../api/CovidApi';

function CovidStatistics() {
    const [regions, setRegions] = useState([]);

    useEffect(() => {
        const doEffect = async () => {
            try {
                const globalResult = await CovidApi.getGlobal();
                const brasilCases = globalResult.data.Countries.find((c) => c.Slug === 'brazil');

                const stateResult = await CovidApi.getState('rs');

                const newRegions = [
                    ...regions,
                    {
                        name: 'Mundo',
                        confirmed: globalResult.data.Global.TotalConfirmed,
                        deaths: globalResult.data.Global.TotalDeaths
                    },
                    { name: 'Brasil', confirmed: brasilCases.TotalConfirmed, deaths: brasilCases.TotalDeaths },
                    {
                        name: 'RS',
                        confirmed: stateResult.data.cases,
                        deaths: stateResult.data.deaths
                    }
                ];
                localStorage.setItem('cached-regions', JSON.stringify(newRegions));
                setRegions(newRegions);
            } catch (error) {
                setRegions(JSON.parse(localStorage.getItem('cached-regions')));
            }
        };

        doEffect();
        // eslint-disable-next-line
    }, []);
    return (
        <div className="section">
            <InfoTitle text="Dados Covid" />
            <Row className="align-center justify-center">
                <Row className="breakable-row align-center justify-center">
                    {regions.map((region, index) => (
                        <div key={index} className="breakable-item">
                            <CovidRegion region={region} />
                        </div>
                    ))}
                </Row>
            </Row>
        </div>
    );
}

export default CovidStatistics;
