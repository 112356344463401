import Axios from 'axios';
class CovidApi {
    static async getGlobal() {
        return await Axios({
            method: 'get',
            url: 'https://api.covid19api.com/summary'
        });
    }

    static async getCity(ibgeCode) {
        const date = new Date();
        date.setDate(date.getDate() - 5);
        const dateStr = date.toISOString().substring(0, 10);
        return await Axios({
            method: 'get',
            url: `https://brasil.io/api/dataset/covid19/caso/data/?format=json&city_ibge_code=${ibgeCode}&date=${dateStr}`
        });
    }

    static async getState(uf) {
        return await Axios({
            method: 'get',
            url: `https://covid19-brazil-api.now.sh/api/report/v1/brazil/uf/${uf}`
        });
    }
}

export default CovidApi;
