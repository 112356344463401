import React from 'react';
import { Typography } from 'antd';

function WordSearched({ value }) {
    return (
        <div className="row align-center justify-center">
            <Typography className="subtitle right-spacing">Resultados para: </Typography>
            <Typography className="left-spacing">{value}</Typography>
        </div>
    );
}

export default WordSearched;
