import React, { useLayoutEffect } from 'react';
import { Layout } from 'antd';

import useIsMobile from '../../../hooks/use_is_mobile';

import './style.scss';
import AppTitle from '../../molecules/app_title';
import InstitutionIcons from '../../molecules/institution_icons';
import HeaderMenuDesktop from '../../molecules/header_menu_desktop';
import HeaderMenuMobile from '../../molecules/header_menu_mobile';
import { useLocation } from 'react-router-dom';

const AntHeader = Layout.Header;

function Header() {
    const { pathname } = useLocation();
    useLayoutEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    const isMobile = useIsMobile();
    return (
        <AntHeader className="header">
            <div className="row align-center justify-space-between full-height">
                <AppTitle />
                {isMobile ? (
                    <>
                        <HeaderMenuMobile />
                    </>
                ) : (
                    <div className="column justify-space-between full-height">
                        <InstitutionIcons />

                        <HeaderMenuDesktop />
                    </div>
                )}
            </div>
        </AntHeader>
    );
}

export default Header;
