const teamMembers = {
    coordinators: [
        {
            name: 'Ana Flávia Souto de Oliveira',
            image: 'https://lexicovid-team-photos.s3-us-west-1.amazonaws.com/foto_ana.png',
            label:
                'Professora do Departamento de Letras Clássicas e Linguística da Universidade Federal de Santa Maria',
            description:
                "Sou professora dos cursos de Letras na área de teorias da linguagem. Meus trabalhos sempre tiveram como orientação teórica a Linguística Cognitiva, um paradigma que defende que o conhecimento que temos sobre a linguagem não pode ser desvinculado do uso que fazemos da linguagem e do conhecimento que temos sobre o mundo. Fiz mestrado e doutorado na UFRGS, com o foco em dicionários monolíngues de inglês para falantes não nativos, os Learner's Dictionaries. No pós-doutorado (UNISINOS), fiz parte da equipe que produziu o Dicionário Olímpico (dicionarioolimpico.com.br). Antes da pandemia de COVID-19, meus bolsistas e eu estavávamos iniciando um projeto de pesquisa sobre a relação entre linguagem e psicopatia. O léxico da COVID-19 nos fez refletir sobre a importância de fornecer amplo acesso às informações sobre o novo coronavírus e sobre as crises sanitária, econômica e política que esse vírus tornou ainda mais evidentes no Brasil."
        }
    ],
    academics: [
        {
            name: 'Camile Heinrich Echevarria',
            image: 'https://lexicovid-team-photos.s3-us-west-1.amazonaws.com/foto_camile.png',
            label: 'Graduanda em Licenciatura em Letras/Português pela UFSM',
            description:
                'Sou graduanda no Curso de Licenciatura em Letras Português e Literaturas da Língua Portuguesa na Universidade Federal de Santa Maria. Participo como aluna pesquisadora no projeto Lexicovid-19, mais especificamente no domínio "Saúde". Como profissional das letras, tenho interesse em apresentar, no dicionário enciclopédico, definições e informações relevantes, retiradas de locais seguros, com uma linguagem mais acessível para alunos entenderem um pouco mais sobre a pandemia e suas consequências na sociedade.'
        },
        {
            name: 'Chrystian Revelles Gatti',
            image: 'https://lexicovid-team-photos.s3-us-west-1.amazonaws.com/foto_chrystian.png',
            label: 'Graduando em Letras - Bacharelado pela UFSM',
            description:
                'Sou graduando do curso de Bacharelado em Língua Portuguesa da UFSM e membro do Programa de Educação Tutorial PET-Letras, começou sua pesquisa como orientando da Profa. Dra. Ana Flávia Souto de Oliveira no projeto Lexicovid-19, atuando especificamente com os cenários do domínio Política.'
        },
        {
            name: 'Guilherme Hatwig Piper',
            image: 'https://lexicovid-team-photos.s3-us-west-1.amazonaws.com/foto_guilherme.png',
            label: 'Estudante de Letras - Bacharelado',
            description:
                'Sou graduando do curso de Bacharelado em Língua Portuguesa da UFSM, meus interesses principais estão na área de linguística e teoria de linguagem. Atualmente participo no projeto Lexicovid-19 como pesquisador, atuando principalmente na área do dicionário pertinente a economia.'
        },
        {
            name: 'Rodrigo Poletto',
            image: 'https://lexicovid-team-photos.s3-us-west-1.amazonaws.com/foto_poletto.png',
            label: 'Graduando em Licenciatura em Letras/Português pela UFSM',
            description:
                'Sou graduando do curso de licenciatura em Letras/Português na Universidade Federal de Santa Maria. Participo como pesquisador do projeto "Lexicovid-19", desenvolvendo, principalmente, o domínio "Saúde". Como profissional de Letras, tenho interesse nas áreas de política, análise discursiva e educação, prioritariamente no campo de ensino de produção textual, educação popular e produção de dicionários. Também atuo na produção gráfica do projeto, desenvolvendo a identidade visual e materiais de apoio para o dicionário enciclopédico.'
        },
        {
            name: 'Luiza Teixeira da Silva',
            image: 'https://lexicovid-team-photos.s3.us-west-1.amazonaws.com/foto_luiza.jpeg',
            label:
                'Graduada em Licenciatura em Letras/Português e Letras/Espanhol pela UFSM e graduanda em Licenciatura em Letras/Espanhol pela mesma universidade',
            description:
                'Sou graduada em licenciatura em Letras Português pela Universidade Federal de Santa Maria e graduanda em Licenciatura em Letras Espanhol pela mesma universidade. Participo como pesquisadora no projeto Lexicovid-19, desenvolvendo, principalmente o domínio saúde e materiais complementares ao dicionário. Como profissional, tenho interesse nas áreas de lexicografia, ensino de linguagem e análise de discurso.'
        },
        {
            name: 'Laura Ceolin Mess',
            image: 'https://lexicovid-team-photos.s3.us-west-1.amazonaws.com/foto_laura.jpeg',
            label:
                'Graduanda em Letras - Bacharelado pela UFSM',
            description:
                'Sou graduanda no curso de Bacharelado em Língua Portuguesa da UFSM. Participo como aluna pesquisadora no projeto Lexicovid-19, atuando na atualização e revisão dos cenários novo-coronavírus e saúde. Destaco a elaboração do domínio Vacina. Dentro da pesquisa, valorizo as mulheres pesquisadoras e tenho interesse em Linguística Cognitiva e Semântica de Frames.'
        }
    ],

    colaborators: [
        {
            name: 'Larissa Moreira Brangel',
            label:
                'Professora do Departamento de Linguística, Filologia e Teoria Literária do Instituto de Letras da Universidade Federal do Rio Grande do Sul',
            image: 'https://lexicovid-team-photos.s3.us-west-1.amazonaws.com/foto_larissa.png',
            description:
                'Sou formada em Letras pela Universidade Federal do Rio Grande do Sul e fiz mestrado e doutorado em Linguística na mesma instituição. Em minha trajetória acadêmica, procuro estabelecer intersecções entre a Lexicografia e a Semântica Cognitiva de modo a propor metodologias que aprimorem os dicionários brasileiros e os aproximem mais de seus consulentes. Fiz estágio de pós-doutoramente na Universidade do Vale do Rio dos Sinos, onde atuei na compilação da segunda edição do dicionário Field - Dicionário de Expressões de Futebol e do Dicionário Paraolímpico. Atualmente, sou professora no Instituto de Letras da Universidade Federal do Rio Grande do Sul e meu interesse de pesquisa recai, principalmente, em aplicar princípios de uma “Lexicografia Cognitiva” nos dicionários escolares brasileiros. Aceitei fazer parte do projeto LEXICOVID-19 porque, como professora e pesquisadora, acredito no impacto que um recurso lexicográfico como esse pode ter junto aos estudantes de nosso país, contribuindo não somente para difusão de informações confiáveis sobre a crise sanitária que vivenciamos, como também para a formação de cidadãos responsáveis e aptos a lidarem com desafios futuros.'
        }
    ],

    developers: [
        {
            name: 'Artur Luis Oliveira',
            image: 'https://lexicovid-team-photos.s3-us-west-1.amazonaws.com/foto_artur.png',
            label: 'Desenvolvedor',
            description:
                'Sou de software e tenho experiência com desenvolvimento web e mobile, formado em produção multimídia e estudante de análise e desenvolvimento de sistemas pelo SenacRS. Participei do projeto programando o site do dicionário.'
        }
    ]
};

export default teamMembers;
