import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { Typography, Breadcrumb, Menu, Tag, Space, Avatar } from 'antd';
import { Link } from 'react-router-dom';

import { DOMAIN, SCENARIO, LEXICAL_UNIT } from '../../../constants/types';

import './style.scss';
import useIsMobile from '../../../hooks/use_is_mobile';

function SectionTitle({ selectedDomain, selectedScenario, selectedWord, tag, domains = [] }) {
    const [title, setTitle] = useState('');
    const [titleClass, setTitleClass] = useState('');
    const [tagColor, setTagColor] = useState('default');

    const isMobile = useIsMobile();
    const type = useMemo(() => {
        if (selectedWord) return LEXICAL_UNIT;
        if (selectedScenario) return SCENARIO;
        if (selectedDomain) return DOMAIN;
    }, [selectedWord, selectedScenario, selectedDomain]);

    /**
     * Returns value based on type selected
     * @param {any} lexicalUnit - Value returned for lexical unit type
     * @param {any} scenario - Value returned for scenario type
     * @param {any} domain - Value returned for domain type
     * @param {any} none - Value returned when type value is not declared
     * @returns {any} selected value
     */
    const dataTypeSelector = useCallback(
        (lexicalUnit, scenario, domain, none) => {
            return {
                [LEXICAL_UNIT]: lexicalUnit,
                [SCENARIO]: scenario,
                [DOMAIN]: domain,
                '': none ? none : ''
            }[type];
        },
        [type]
    );

    useEffect(() => {
        const newTitle = dataTypeSelector(
            selectedWord,
            selectedScenario && selectedScenario.wordForm,
            selectedDomain && selectedDomain.wordForm
        );
        const newTitleClass = dataTypeSelector('lowercase', 'capitalize', 'uppercase');
        const newTagColor = dataTypeSelector('default', 'blue', 'blue', 'default');

        setTitle(newTitle);
        setTitleClass(newTitleClass);
        setTagColor(newTagColor);
    }, [type, selectedScenario, selectedDomain, selectedWord, dataTypeSelector]);

    const domainMenu = (
        <Menu>
            {domains.map((domain, i) => (
                <Menu.Item key={`section-title-menu-item-${i}`}>
                    <Link to={(location) => ({ ...location, search: `?dominio=${domain.wordFormSlug}` })}>
                        {domain.wordForm}
                    </Link>
                </Menu.Item>
            ))}
        </Menu>
    );

    return (
        <Space className="full-width side-wrapper top-spacing-4" direction="horizontal" size="middle">
            {/* <img src={selectedDomain && selectedDomain.iconUrl} alt="domain-icon" /> */}
            <Avatar size={isMobile ? 70 : 120} src={selectedDomain && selectedDomain.iconUrl} />
            <Space direction="vertical" align="start" size={isMobile ? 'small' : 'middle'}>
                <Typography className={`bold ${titleClass} font-size-2`} title>
                    {title}
                </Typography>

                <Tag color={tagColor}>{tag}</Tag>

                {domains && domains[0] && (
                    <Breadcrumb>
                        <Breadcrumb.Item overlay={domainMenu}>{selectedDomain.wordForm}</Breadcrumb.Item>
                        {selectedWord && <Breadcrumb.Item>{selectedScenario.wordForm}</Breadcrumb.Item>}
                    </Breadcrumb>
                )}
            </Space>
        </Space>
    );
}

export default SectionTitle;
