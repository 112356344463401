import React from 'react';
import './style.scss';
import RegionTitle from '../../atoms/region_title';
import RegionInfoContent from '../../atoms/region_info_content';

function CovidRegion({ region }) {
    return (
        <div className="card column align-center">
            <RegionTitle text={region.name} />
            <div>
                <RegionInfoContent title="Confirmados" content={region.confirmed} />
                <RegionInfoContent title="Óbitos" content={region.deaths} />
            </div>
        </div>
    );
}

export default CovidRegion;
