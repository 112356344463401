import React from 'react';
import { Layout, Typography, Row, Col } from 'antd';

import SocialMediaIcons from '../../atoms/social_media_icons';
import useIsMobile from '../../../hooks/use_is_mobile';

import './style.scss';

const AntFooter = Layout.Footer;

function Footer() {
    const isMobile = useIsMobile();
    return (
        <AntFooter className="footer top-spacing-8">
            {isMobile ? (
                <div className="flex align-center justify-center">
                    <Typography className="color-white">Lexicovid ® 2020</Typography>
                </div>
            ) : (
                <Row>
                    <Col span={8}></Col>
                    <Col span={8} className="flex align-center justify-center">
                        <Typography className="color-white">Lexicovid ® 2020</Typography>
                    </Col>
                    <Col span={8} className="flex align-center justify-center">
                        {' '}
                        <SocialMediaIcons />
                    </Col>
                </Row>
            )}
        </AntFooter>
    );
}

export default Footer;
