import React, { useState, useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { Layout } from 'antd';

import * as factories from '../../../factories';
import useQuery from '../../../hooks/use_query';
import { LEXICAL_UNIT } from '../../../constants/types';

import Header from '../../organisms/header';
import Footer from '../../organisms/footer';
import ScenarioApi from '../../../api/ScenarioApi';
import SectionTitle from '../../molecules/section_title';
import TextDescription from '../../atoms/text_description';
import WordList from '../../molecules/word_list';
import Infographic from '../../molecules/infographic';
import ScenarioImage from '../../molecules/scenario_image';
import useVideoLink from '../../../hooks/use_video_link';

import './style.scss';
import UseIsMobile from '../../../hooks/use_is_mobile';
import ScenarioHead from '../../heads/scenario';
import KnowMoreSection from '../../organisms/know_more_section';

const Content = Layout.Content;

function Scenario() {
    const [scenario, setScenario] = useState(factories.Scenario());
    const [lexicalUnits, setLexicalUnits] = useState([]);

    const { slug } = useParams();
    const domainQuery = useQuery('dominio');
    const isMobile = UseIsMobile();

    useEffect(() => {
        ScenarioApi.get(slug)
            .then((response) => {
                setScenario(response.data.data);
            })
            .catch((err) => {
                console.error(err);
            });
    }, [slug]);

    useEffect(() => {
        if (domainQuery && scenario && scenario.lexicalUnits) {
            //filter by domain query
            setLexicalUnits(
                scenario.lexicalUnits.filter((lexicalUnit) => lexicalUnit.domainsSlugs.includes(domainQuery))
            );
            return;
        }
        if (scenario && scenario.lexicalUnits) setLexicalUnits(scenario.lexicalUnits);
    }, [scenario, domainQuery]);

    const videoLink = useVideoLink(scenario.videoUrl);

    const youtubeVideo = isMobile ? (
        <iframe
            title="mobile"
            type="text/html"
            className="top-spacing-4 top-spacing-4"
            src={videoLink}
            width="280"
            frameBorder="157.5"
            allowFullScreen
        />
    ) : (
        <iframe
            title="desktop"
            className="left-spacing-4 top-spacing-4"
            type="text/html"
            src={videoLink}
            width="560"
            height="315"
            frameBorder="0"
            allowFullScreen
        />
    );

    const infographicIsVisible = useMemo(
        () =>
            scenario.horizontalInfographicUrl &&
            scenario.horizontalInfographicUrl.lenght &&
            scenario.verticalInfographicUrl &&
            scenario.verticalInfographicUrl.length,
        [scenario]
    );

    const links = useMemo(() => {
        const newLinks = [];
        if (scenario && scenario.articleName && scenario.articleUrl)
            newLinks.push({ description: scenario.articleName, url: scenario.articleUrl });
        if (scenario && scenario.paperName && scenario.paperUrl)
            newLinks.push({ description: scenario.paperName, url: scenario.paperUrl });
        return newLinks;
    }, [scenario]);

    const imageIsVisible = useMemo(() => scenario.imageUrl && scenario.imageUrl.length, [scenario]);

    return (
        <>
            <ScenarioHead
                scenario={scenario && scenario.wordForm}
                domains={scenario && scenario.domains}
                definition={scenario && scenario.definition}
            />
            <Layout className="page">
                <Header />
                <Content className="content">
                    <SectionTitle
                        selectedDomain={scenario && scenario.domains && scenario.domains[0]}
                        selectedScenario={scenario}
                        domains={scenario && scenario.domains}
                        tag={'Cenário'}
                    />

                    <div className={`text-and-list-wrapper${isMobile ? '__mobile' : '__desktop'}`}>
                        <TextDescription>{scenario && scenario.definition}</TextDescription>
                        <div className="content-side-padding">
                            <WordList
                                listType={LEXICAL_UNIT}
                                className="top-spacing-4"
                                color="primary"
                                title="Ítens Lexicais"
                                list={lexicalUnits}
                                domainSlug={domainQuery}
                            />
                        </div>
                    </div>
                    <div className="top-spacing-4" />
                    {scenario && scenario.videoUrl && youtubeVideo}
                    {infographicIsVisible && (
                        <div className="full-width top-spacing-8">
                            <Infographic
                                className="top-spacing-8 full-width"
                                isMobile={isMobile}
                                horizontalSrc={scenario && scenario.horizontalInfographicUrl}
                                verticalSrc={scenario && scenario.verticalInfographicUrl}
                            />
                        </div>
                    )}
                    {imageIsVisible && (
                        <div className="full-width top-spacing-8">
                            <ScenarioImage className="top-spacing-8 full-width" src={scenario && scenario.imageUrl} />
                        </div>
                    )}
                    {scenario && (
                        <div className="full-width">
                            <KnowMoreSection links={links} iconColor="red" isMobile={isMobile} />
                        </div>
                    )}
                </Content>
                <Footer />
            </Layout>
        </>
    );
}

export default Scenario;
