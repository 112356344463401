import React from 'react';
import { Typography } from 'antd';

import './style.scss';

function DomainIcon({ handleClick, imgSrc, imgAlt, title }) {
    return (
        <div className="column align-center justify-center" onClick={handleClick}>
            <div className="domain-img-container flex align-center justify-center">
                <img className="domain-img" src={imgSrc} alt={imgAlt} />
            </div>
            <Typography className="domain-icon-title">{title}</Typography>
        </div>
    );
}

export default DomainIcon;
