import React from 'react';
import { Typography } from 'antd';
import useIsMobile from '../../../hooks/use_is_mobile/index.jsx';

const { Paragraph } = Typography;

function HomeDescription() {
    const isMobile = useIsMobile();
    return (
        <>
            <Typography className="paragraph">
                <Paragraph className="justify-text">
                    O <i>LEXICOVID-19</i> apresenta palavras, termos e expressões relacionados à pandemia de COVID-19 de
                    forma temática, com base nos contextos que dão suporte ao entendimento desses itens lexicais.
                </Paragraph>
                {!isMobile && (
                    <Paragraph className="justify-text">
                        A propagação do <strong>novo coronavírus</strong> pelo mundo impactou diretamente a área da{' '}
                        <strong>saúde</strong>, fazendo com que governos buscassem estratégias para evitar um colapso em
                        seus sistemas de saúde e estabelecessem medidas de prevenção e controle da doença. Com a
                        população adotando distanciamento social e com o fechamento de comércios e serviços, a pandemia
                        trouxe diversas consequências à <strong>economia</strong>. Mudanças no perfil de consumo e a
                        necessidade de se estabelecer formas mínimas de renda à população geraram demanda por políticas
                        de mitigação dos impactos econômicos. No Brasil e em outras partes do mundo, a pandemia ainda
                        acirrou a polarização social, instaurando uma crise <strong>política</strong> de grandes
                        proporções e mobilizando agentes públicos no combate ao vírus e suas consequências.
                    </Paragraph>
                )}
                <Paragraph className="justify-text">
                    Para saber mais sobre a COVID-19 e seu impacto nos domínios da saúde, economia e política, comece a
                    navegação pelas opções abaixo.
                </Paragraph>
            </Typography>
        </>
    );
}

export default HomeDescription;
