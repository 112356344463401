import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Spin, Layout, Table, Row, Col } from 'antd';
import { useHistory } from 'react-router-dom';

import LexicalUnitApi from '../../../api/LexicalUnitApi';
import useIsMobile from '../../../hooks/use_is_mobile';

import Header from '../../organisms/header';
import Footer from '../../organisms/footer';
import SearchBar from '../../molecules/search_bar';
import WordSearched from '../../molecules/word_searched';

import './style.scss';
import SearchHead from '../../heads/search';

const Content = Layout.Content;

function Search() {
    let { userInput } = useParams();
    const history = useHistory();
    const isMobile = useIsMobile();

    const [isfechingData, setIsFetchingData] = useState(false);
    const [lexicalUnits, setLexicalUnits] = useState([]);
    const [displayedLexicalUnits, setDisplayedLexicalUnits] = useState([]);

    const [filter, setFilter] = useState('');

    useEffect(() => {
        if (userInput) setFilter(userInput);
    }, [userInput]);

    useEffect(() => {
        setIsFetchingData(true);
        LexicalUnitApi.list()
            .then((response) => {
                setLexicalUnits(response.data.data);
                setIsFetchingData(false);
            })
            .catch((err) => console.error(err));
    }, []);

    useEffect(() => {
        if (userInput)
            setDisplayedLexicalUnits(
                lexicalUnits.filter(
                    (lexicalUnit) =>
                        lexicalUnit && lexicalUnit.wordFormSlug && lexicalUnit.wordFormSlug.includes(userInput)
                )
            );
        if (!userInput) setDisplayedLexicalUnits(lexicalUnits);
    }, [filter, lexicalUnits, userInput]);

    return (
        <>
            <SearchHead />
            <Layout className="page">
                <Header />
                <Content className="content">
                    <Spin size="large" spinning={isfechingData}>
                        {/* content */}
                        <div className="top-spacing-4">
                            <Row>
                                <Col span={isMobile ? 2 : 6} />
                                <Col span={isMobile ? 20 : 12}>
                                    <SearchBar />
                                </Col>
                                <Col span={isMobile ? 2 : 6} />
                            </Row>
                        </div>
                        <div className="top-spacing-2">
                            <WordSearched value={userInput ? userInput.replace('-', ' ') : 'A - Z'} />
                        </div>

                        <div className="top-spacing-2">
                            <Table
                                onRow={(record) => ({
                                    onClick: () => history.push(`/palavra/${record.wordFormSlug}`) // click row
                                })}
                                pagination={true}
                                locale={{ emptyText: 'Nenhuma palavra encontrada' }}
                                columns={[
                                    {
                                        defaultSortOrder: 'descend',
                                        title: 'Itens Lexicais',
                                        dataIndex: 'wordForm',
                                        key: 'wordForm',
                                        align: 'center'
                                    }
                                ]}
                                dataSource={displayedLexicalUnits.sort((a, b) => {
                                    if (
                                        a &&
                                        a.wordForm &&
                                        a.wordForm &&
                                        a.wordForm.toLowerCase() &&
                                        b &&
                                        b.wordForm &&
                                        b.wordForm.toLowerCase()
                                    ) {
                                        if (a.wordForm[0].toUpperCase() != b.wordForm[0].toLowerCase()) return 0;

                                        if (a.wordForm < b.wordForm) {
                                            return -1;
                                        }
                                        if (a.wordForm > b.wordForm) {
                                            return 1;
                                        }
                                    }
                                    return 0;
                                })}
                            />
                        </div>
                    </Spin>
                </Content>
                <Footer />
            </Layout>
        </>
    );
}

export default Search;
