import React from 'react';
import { Button } from 'antd';
import { YoutubeFilled, InstagramFilled, GoogleOutlined } from '@ant-design/icons';

function SocialMediaIcons() {
    return (
        <div className="row justify-center align-center">
            <Button type="ghost" size="large" className="remove-border">
                <YoutubeFilled className="color-white" />
            </Button>
            <Button type="ghost" size="large" className="remove-border">
                <InstagramFilled className="color-white" />
            </Button>
            <Button type="ghost" size="large" className="remove-border">
                <GoogleOutlined className="color-white" />
            </Button>
        </div>
    );
}

export default SocialMediaIcons;
