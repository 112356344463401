import React from 'react';
import './style.scss';

function InstitutionIcons() {
    return (
        <div className="row justify-center align-center">
            <div>
                <a href="https://www.ufsm.br/" target="_blank" rel="noopener noreferrer" className="ufsm_logo">
                    <img
                        src={`${process.env.PUBLIC_URL}/assets/images/ufsm_logo.png`}
                        className="ufsm_logo"
                        alt="ufsm_logo"
                    />
                </a>
            </div>
            <div>
                <a
                    href="https://www.ufsm.br/unidades-universitarias/cal/departamentos/dlcl/"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    <img
                        src={`${process.env.PUBLIC_URL}/assets/images/dlcl_logo.svg`}
                        className="dlcl_logo"
                        alt="dlcl_logo"
                    />
                </a>
            </div>
        </div>
    );
}

export default InstitutionIcons;
