import React from 'react';
import './style.scss';

const formatNumber = (num) => {
    return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.');
};

function RegionInfoContent({ title, content }) {
    return (
        <div className="info-container">
            <span className="info-title">{title}</span>
            <span className="info-value">{formatNumber(content)}</span>
        </div>
    );
}

export default RegionInfoContent;
