import React from 'react';
import { Row } from 'antd';
import { useHistory } from 'react-router-dom';

import DomainIcon from '../../atoms/domain_icon';

import './style.scss';

function DomainIconsSection({ domains = [] }) {
    const history = useHistory();
    return (
        <Row className="align-center justify-center top-spacing-4">
            <Row className="breakable-row align-center justify-center">
                {domains.map((domain, index) => (
                    <div key={`domain-icon-${index}`} className="breakable-item">
                        <DomainIcon
                            handleClick={() => history.push(`dominio/${domain.wordFormSlug}`)}
                            imgSrc={domain.iconUrl}
                            imgAlt={domain.wordFormSlug}
                            title={domain.wordForm}
                        />
                    </div>
                ))}
            </Row>
        </Row>
    );
}

export default DomainIconsSection;
