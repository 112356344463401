import React, { useMemo } from 'react';
import _ from 'lodash';

import { Typography, List } from 'antd';
import { Link } from 'react-router-dom';

import './style.scss';

function WordList({ title, listType, list, domainSlug, scenarioSlug, color = 'primary', className, ...props }) {
    const search = '?' + (domainSlug ? `dominio=${domainSlug}` : '') + (scenarioSlug ? `cenario=${scenarioSlug}` : '');
    const dataSource = useMemo(() => _.orderBy(list, ['wordForm']), [list]);
    return (
        <List
            {...props}
            className={'word-list ' + color + ' ' + className}
            header={<Typography>{title}</Typography>}
            bordered
            dataSource={dataSource}
            renderItem={(item) => (
                <List.Item>
                    <Link
                        to={{
                            pathname: `/${(() => {
                                return { SCENARIO: 'cenario', LEXICAL_UNIT: 'palavra' }[listType];
                            })()}/${item.wordFormSlug}`,
                            search
                        }}
                    >
                        {item.wordForm}
                    </Link>
                </List.Item>
            )}
        />
    );
}

export default WordList;
