import React from 'react';
import './style.scss';

import { Button } from 'antd';
import { useHistory } from 'react-router-dom';
import SearchBar from '../../molecules/search_bar';

function SearchSection() {
    const history = useHistory();
    return (
        <div
            style={{ backgroundImage: `url(${process.env.PUBLIC_URL}/assets/images/search_background.png)` }}
            className="search-section-container column align-center justify-center"
        >
            <SearchBar />
            <Button className="a-z-btn top-spacing" onClick={() => history.push('/busca')} type="link">
                Palavras de A - Z
            </Button>
        </div>
    );
}

export default SearchSection;
