import React from 'react';

import InfoTitle from '../../atoms/info_title';

import './style.scss';

function ScenarioImage({ className, src, ...props }) {
    return (
        <div className={'column align-center justify-center' + className} {...props}>
            <InfoTitle text="Imagem" />
            <img className="top-spacing-2 scenario-img" src={src} alt="scenario" />
        </div>
    );
}

export default ScenarioImage;
