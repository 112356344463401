import React, { useMemo } from 'react';
import { Helmet } from 'react-helmet';
import { toFirstLetterToUpperCase } from '../../../utils/letter_caser';
import { getFirstCharacters } from '../../../utils/text_filter';

function ScenarioHead({ scenario = '', domains = [], definition = '' }) {
    const title = useMemo(() => `${toFirstLetterToUpperCase(scenario)} - Cenário`, [scenario]);
    const description = useMemo(() => getFirstCharacters(definition, 100), [definition]);
    const keywords = useMemo(
        () => [scenario, ...domains, 'coronavírus', 'covid', 'dicionário', 'enciclopédia'].toString(),
        [scenario, domains]
    );

    if (!scenario.length) return null;
    return (
        <Helmet lang="pt-br" title={title}>
            <meta charset="utf-8" />
            <meta name="viewport" content="width=device-width, initial-scale=1" />
            <meta name="description" content={description} />
            <meta name="keywords" content={keywords} />
        </Helmet>
    );
}

export default ScenarioHead;
