import Axios from '../services/api';
class DomainApi {
    static async list() {
        return await Axios({
            method: 'get',
            url: '/domains'
        });
    }
    static async get(slug) {
        return await Axios({
            method: 'get',
            url: `/domains/${slug}`
            // params: { slug }
        });
    }
}

export default DomainApi;
