import Axios from '../services/api';
class LexicalUnitApi {
    static async list() {
        return await Axios({
            method: 'get',
            url: '/lexical-units'
        });
    }
    static async get(slug) {
        return await Axios({
            method: 'get',
            url: `/lexical-units/${slug}`
        });
    }
}
export default LexicalUnitApi;
