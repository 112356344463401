/* eslint-disable react/jsx-filename-extension */
import React from 'react';
import { hydrate, render } from 'react-dom';

import './index.css';
import './global.scss';
import * as serviceWorker from './serviceWorker';

import { version as APP_VERSION } from '../package.json';
import App from './components/app';

console.info(`%cApp version: ${APP_VERSION}`, 'background: blue; color: yellow; font-size: x-large');

const rootElement = document.getElementById('root');
if (rootElement.hasChildNodes()) {
    hydrate(<App />, rootElement);
} else {
    render(<App />, rootElement);
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
