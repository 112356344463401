import React from 'react';
import { Typography } from 'antd';

import './style.scss';

function WordAttribute({ title, description, isItalic, isMobile }) {
    return (
        <div className={`top-spacing-4 ${isMobile ? '' : 'column align-start content-side-padding'}`}>
            <Typography className="word-attribute-title bold">{title}</Typography>
            <Typography className={isItalic ? 'italic' : ''}>{description}</Typography>
        </div>
    );
}

export default WordAttribute;
