import React, { useMemo } from 'react';
import { Helmet } from 'react-helmet';
import { toFirstLetterToUpperCase } from '../../../utils/letter_caser';
import { getFirstCharacters } from '../../../utils/text_filter';

function WordHead({ word = '', domains = '', scenario = '', definition = '' }) {
    const title = useMemo(() => `${toFirstLetterToUpperCase(word)} - Ítem Lexical`, [word]);
    const description = useMemo(() => getFirstCharacters(definition, 100), [definition]);
    const keywords = useMemo(
        () =>
            [
                word,
                ...domains,
                scenario,
                'coronavírus',
                'covid',
                'dicionário',
                'enciclopédia',
                'palavra',
                'ítem lexical'
            ].toString(),
        [word, domains, scenario]
    );

    if (!word.length) return null;
    return (
        <Helmet lang="pt-br" title={title}>
            <meta charset="utf-8" />
            <meta name="viewport" content="width=device-width, initial-scale=1" />
            <meta name="description" content={description} />
            <meta name="keywords" content={keywords} />
        </Helmet>
    );
}

export default WordHead;
