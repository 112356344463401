import React from 'react';
import { Layout, Result, Button } from 'antd';
import { useHistory } from 'react-router-dom';
import Header from '../../organisms/header';
import Footer from '../../organisms/footer';

const Content = Layout.Content;

function Error404() {
    const history = useHistory();
    return (
        <Layout className="page">
            <Header />
            <Content>
                <Result
                    status="404"
                    title="404"
                    subTitle="Desculpe, a página que você está tentando acessar não existe."
                    extra={
                        <Button onClick={() => history.push('/')} type="primary">
                            Voltar à home
                        </Button>
                    }
                />
            </Content>
            <Footer />
        </Layout>
    );
}

export default Error404;
