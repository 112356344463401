import React from 'react';
import InfoTitle from '../../atoms/info_title';

import DescribedLink from '../../atoms/described_link';

function KnowMoreSection({ links, iconColor }) {
    return (
        <>
            {links && !!links.length && links.length > 0 && (
                <div className="column justify-center align-center top-spacing-8">
                    <InfoTitle text="Saiba Mais" color="primary-color" />
                    {links.map((link, index) => (
                        <DescribedLink
                            key={`described-link-${index}`}
                            url={link && link.url}
                            description={link && link.description}
                            iconColor={iconColor}
                        />
                    ))}
                </div>
            )}
        </>
    );
}

export default KnowMoreSection;
