import React, { useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Layout, Row, Col } from 'antd';
//import { Spin, Layout, Row, Col } from 'antd';

import { Domain, Scenario, LexicalUnit } from '../../../factories';
import LexicalUnitApi from '../../../api/LexicalUnitApi';
import useQuery from '../../../hooks/use_query';

import Header from '../../organisms/header';
import Footer from '../../organisms/footer';
import KnowMoreSection from '../../organisms/know_more_section';
import SectionTitle from '../../molecules/section_title';
import TextDescription from '../../atoms/text_description';
import WordAttribute from '../../atoms/word_attribute';
import useIsMobile from '../../../hooks/use_is_mobile';

import './style.scss';
import WordHead from '../../heads/word';

const Content = Layout.Content;

function Word() {
    //const [isfechingData, setIsFetchingData] = useState(false);
    const [lexicalUnit, setLexicalUnit] = useState(LexicalUnit({ scenario: Scenario() }));
    const [selectedDomain, setSelectedDomain] = useState(Domain());

    const isMobile = useIsMobile();
    const history = useHistory();
    let { slug } = useParams();
    const domainQuery = useQuery('dominio');

    /**
     * Try to set the domain to the query string value, if none is provided sets the domain to the first value.
     */
    useEffect(() => {
        if (domainQuery && lexicalUnit && lexicalUnit.domains && lexicalUnit.domains.length > 0) {
            const filteredDomain = lexicalUnit.domains.find((domain) => domain.wordFormSlug === domainQuery);
            //goes to 404 in case wrong parameter is passed.
            if (!filteredDomain) history.push('/error/404');
            setSelectedDomain(filteredDomain);
            //return;
        }
        if (
            !domainQuery &&
            lexicalUnit &&
            lexicalUnit.domains &&
            lexicalUnit.domains.length &&
            lexicalUnit.domains.length > 0 &&
            lexicalUnit.domains[0]
        ) {
            setSelectedDomain(lexicalUnit.domains[0]);
            return;
        }
    }, [domainQuery, lexicalUnit, history]);

    /**
     * API Handler
     */
    useEffect(() => {
        //setIsFetchingData(true);
        LexicalUnitApi.get(slug)
            .then((response) => {
                setLexicalUnit(response.data.data);
                //setIsFetchingData(false);
            })
            .catch((err) => {
                console.error(err);
            });
    }, [slug]);

    return (
        <>
            <WordHead
                word={lexicalUnit && lexicalUnit.wordForm}
                definition={lexicalUnit && lexicalUnit.definition}
                domains={lexicalUnit && lexicalUnit.domains}
                scenario={lexicalUnit && lexicalUnit.scenario}
            />
            <Layout className="page">
                <Header />
                <Content className="content">
                    {/* <Spin className="full-width full-height" size="large" spinning={isfechingData}> */}
                    <SectionTitle
                        selectedDomain={selectedDomain}
                        selectedScenario={lexicalUnit.scenario}
                        domains={lexicalUnit.domains}
                        selectedWord={lexicalUnit.wordForm}
                        tag={lexicalUnit.partOfSpeech}
                    />
                    <Row>
                        <Col span={isMobile ? 24 : 16}>
                            <TextDescription>{lexicalUnit && lexicalUnit.definition}</TextDescription>
                            <div className="full-width">
                                {/* Render only in Mobile */}
                                {isMobile && lexicalUnit && lexicalUnit.imageUrl && (
                                    <img
                                        className={'word-image__mobile'}
                                        src={lexicalUnit.imageUrl}
                                        alt={`word-${lexicalUnit.wordFormSlug}`}
                                    />
                                )}
                                {lexicalUnit && lexicalUnit.variant && (
                                    <WordAttribute
                                        title="Variante(s)"
                                        description={lexicalUnit.variant.toString()}
                                        isMobile={isMobile}
                                    />
                                )}
                                {lexicalUnit && lexicalUnit.useExample && (
                                    <WordAttribute
                                        title="Exemplo de uso"
                                        description={lexicalUnit.useExample}
                                        isItalic
                                        isMobile={isMobile}
                                    />
                                )}
                                {lexicalUnit && lexicalUnit.equivalent && (
                                    <WordAttribute
                                        title="Equivalente em inglês"
                                        description={lexicalUnit.equivalent}
                                        isMobile={isMobile}
                                    />
                                )}
                            </div>
                        </Col>
                        {/* Render only in Desktop */}
                        <Col span={8}>
                            <div className="full-width right-spacing-4">
                                {!isMobile && lexicalUnit && lexicalUnit.imageUrl && (
                                    <img
                                        className="word-image__desktop"
                                        src={lexicalUnit.imageUrl}
                                        alt={`word-${lexicalUnit.wordFormSlug}`}
                                    />
                                )}
                            </div>
                        </Col>

                        {lexicalUnit && lexicalUnit.linkUrl && lexicalUnit.linkName && (
                            <div className="full-width">
                                <KnowMoreSection
                                    links={[{ description: lexicalUnit.linkName, url: lexicalUnit.linkUrl }]}
                                    iconColor="red"
                                    isMobile={isMobile}
                                />
                            </div>
                        )}

                        {/* </Spin> */}
                    </Row>
                </Content>
                <Footer />
            </Layout>
        </>
    );
}

export default Word;
