import React, { useState, useEffect } from 'react';
import { Spin, Layout } from 'antd';

import DomainApi from '../../../api/DomainApi';

import './style.scss';

import Header from '../../organisms/header';
import Footer from '../../organisms/footer';
import SearchSection from '../../organisms/search_section';
import DomainIconsSection from '../../organisms/domain_icons_section';
// import Carousel from '../../organisms/carousel';
import CovidStatistics from '../../organisms/covid_statistics';
import HomeHead from '../../heads/home';
import HomeDescription from '../../organisms/home_description';

const Content = Layout.Content;
function Home() {
    const [isfechingData, setIsFetchingData] = useState(false);
    const [domains, setDomains] = useState([]);

    useEffect(() => {
        setIsFetchingData(true);
        DomainApi.list().then((response) => {
            setDomains(response.data.data);
            setIsFetchingData(false);
        });
    }, []);

    return (
        <>
            <HomeHead />
            <Layout className="page">
                <Header />
                <Spin size="large" spinning={isfechingData}>
                    <Content>
                        <Spin size="large" spinning={isfechingData}>
                            <HomeDescription />
                            <DomainIconsSection domains={domains} />
                            <SearchSection />
                            {/* <Carousel /> */}
                            <CovidStatistics />
                        </Spin>
                    </Content>
                </Spin>
                <Footer />
            </Layout>
        </>
    );
}

export default Home;
