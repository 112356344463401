import React from 'react';
import { Typography } from 'antd';

import './style.scss';

function AppTitle({ icon, title, description, url }) {
    return (
        <a href={url} target="_blank" rel="noopener noreferrer">
            <div className="column align-center">
                {icon}
                <Typography className="subtitle">{title}</Typography>
                <Typography className="paragraph">{description}</Typography>
            </div>
        </a>
    );
}

export default AppTitle;
