export const Domain = (data) => ({
    wordForm: '',
    wordFormSlug: '',
    definition: '',
    iconUrl: '',
    videoUrl: '',
    verticalInfographicUrl: '',
    horizontalInfographicUrl: '',
    articleName: '',
    articleUrl: '',
    paperName: '',
    paperUrl: '',
    ...data
});

export const Scenario = (data) => ({
    wordForm: '',
    wordFormSlug: '',
    domainsSlugs: [],
    definition: '',
    verticalInfographicUrl: '',
    horizontalInfographicUrl: '',
    imageUrl: '',
    videoUrl: '',
    articleName: '',
    articleUrl: '',
    paperName: '',
    paperUrl: '',
    ...data
});

export const LexicalUnit = (data) => ({
    wordForm: '',
    wordFormSlug: '',
    scenarioSlug: '',
    domainsSlugs: [],
    partOfSpeech: '',
    definition: '',
    useExample: '',
    equivalent: [],
    variant: [],
    imageUrl: '',
    linkUrl: '',
    linkName: '',
    ...data
});
