import React from 'react';
import { Layout } from 'antd';
import teamMembers from '../../../constants/teamMembers';

import Header from '../../organisms/header';
import Footer from '../../organisms/footer';
import InfoTitle from '../../atoms/info_title';
import TeamMember from '../../molecules/team_member';
import './style.scss';
import TeamHead from '../../heads/team';

const Content = Layout.Content;

function Team() {
    return (
        <>
            <TeamHead />
            <Layout className="page">
                <Header />
                <Content className="content">
                    <div className="vertical-spacing-4">
                        <InfoTitle text="Coordenadora" />
                    </div>
                    {teamMembers.coordinators.map(({ name, description, label, image }) => {
                        return (
                            <div key={name} className="vertical-spacing-4">
                                <TeamMember
                                    image={image || 'mock.png'}
                                    name={name || ''}
                                    description={label || ''}
                                    longDescription={description || ''}
                                />
                            </div>
                        );
                    })}

                    <div className="vertical-spacing-4">
                        <InfoTitle text="Acadêmicos" color="secondary-color" />
                    </div>
                    {teamMembers.academics.map(({ name, description, label, image }) => {
                        return (
                            <div key={name} className="vertical-spacing-4">
                                <TeamMember
                                    image={image || 'mock.png'}
                                    name={name || ''}
                                    description={label || ''}
                                    longDescription={description || ''}
                                />
                            </div>
                        );
                    })}

                    <div className="vertical-spacing-4">
                        <InfoTitle text="Colaboradores" />
                    </div>
                    {teamMembers.colaborators.map(({ name, description, label, image }) => {
                        return (
                            <div key={name} className="vertical-spacing-4">
                                <TeamMember
                                    image={image || 'mock.png'}
                                    name={name || ''}
                                    description={label || ''}
                                    longDescription={description || ''}
                                />
                            </div>
                        );
                    })}

                    <div className="vertical-spacing-4">
                        <InfoTitle text="Desenvolvedores" color="secondary-color" />
                    </div>
                    {teamMembers.developers.map(({ name, description, label, image }) => {
                        return (
                            <div key={name} className="vertical-spacing-4">
                                <TeamMember
                                    image={image || 'mock.png'}
                                    name={name || ''}
                                    description={label || ''}
                                    longDescription={description || ''}
                                />
                            </div>
                        );
                    })}
                </Content>
                <Footer />
            </Layout>
        </>
    );
}

export default Team;
