import React from 'react';
import { Typography } from 'antd';
import { LinkOutlined } from '@ant-design/icons';

function DescribedLink({ url, description, iconColor }) {
    return (
        <div className="row align-center justify-start top-spacing">
            <LinkOutlined style={{ color: iconColor }} />
            <a href={url} rel="noopener noreferrer" target="_blank">
                <Typography>{description}</Typography>
            </a>
        </div>
    );
}

export default DescribedLink;
