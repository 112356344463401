import React from 'react';
// import { Typography } from 'antd';
// import { Link } from 'react-router-dom';

import InfoTitle from '../../atoms/info_title';

import './style.scss';

function Inforgraphic({ className, verticalSrc, horizontalSrc, isMobile, ...props }) {
    return (
        <div className={'column align-center justify-center' + className} {...props}>
            <InfoTitle text="Infográfico" />
            {/* show api data here */}
            <img
                className="top-spacing-4 infographic-img"
                src={isMobile ? verticalSrc : horizontalSrc}
                alt="inforgraphic"
            />
        </div>
    );
}

export default Inforgraphic;
