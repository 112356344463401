import React from 'react';
import { Helmet } from 'react-helmet';

function SearchHead() {
    const title = 'Busca';
    const description = 'Buscar no Dicionário Enciclopédico do Novo Coronavírus';
    const keywords = 'busca, coronavírus, dicionário, enciclopédia';

    return (
        <Helmet lang="pt-br" title={title}>
            <meta charset="utf-8" />
            <meta name="viewport" content="width=device-width, initial-scale=1" />
            <meta name="description" content={description} />
            <meta name="keywords" content={keywords} />
        </Helmet>
    );
}

export default SearchHead;
