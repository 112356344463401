import React, { useState } from 'react';
import { Button, Drawer } from 'antd';
import { MenuOutlined } from '@ant-design/icons';

import AppTitle from '../../molecules/app_title';
import InstitutionIcons from '../../molecules/institution_icons';
import SocialMediaIcons from '../../atoms/social_media_icons';

import './style.scss';
import { useHistory } from 'react-router-dom';

function HeaderMenuMobile() {
    const history = useHistory();
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    return (
        <div>
            <Button
                onClick={() => setIsMenuOpen(true)}
                className="hamburguer-button"
                type="primary"
                icon={<MenuOutlined />}
                size="large"
            />
            <Drawer
                visible={isMenuOpen}
                headerStyle={{ backgroundColor: 'white' }}
                drawerStyle={{ backgroundColor: 'white' }}
                title={<AppTitle />}
                placement="left"
                onClose={() => setIsMenuOpen(false)}
                width="80vw"
            >
                <div className="column justify-space-between full-height">
                    <div className="column justify-space-around ">
                        <Button onClick={() => history.push('/')} type="link" className="color-primary" size="large">
                            Dicionário
                        </Button>
                        <Button
                            onClick={() => history.push('/equipe')}
                            type="link"
                            className="color-primary"
                            size="large"
                        >
                            Equipe
                        </Button>
                        <Button
                            onClick={() => history.push('/contato')}
                            type="link"
                            className="color-primary"
                            size="large"
                        >
                            Contato
                        </Button>
                    </div>
                    <div>
                        <InstitutionIcons />
                        <SocialMediaIcons />
                    </div>
                </div>
            </Drawer>
        </div>
    );
}

export default HeaderMenuMobile;
