import React, { useMemo } from 'react';
import { Helmet } from 'react-helmet';
import { toFirstLetterToUpperCase } from '../../../utils/letter_caser';
import { getFirstCharacters } from '../../../utils/text_filter';

function DomainHead({ domain = '', definition = '', scenarios = [] }) {
    const title = useMemo(() => `${toFirstLetterToUpperCase(domain)} - Domínio`, [domain]);
    const description = useMemo(() => getFirstCharacters(definition, 100), [definition]);
    const keywords = useMemo(
        () => [domain, ...scenarios, 'coronavírus', 'covid', 'dicionário', 'enciclopédia'].toString(),
        [domain, scenarios]
    );

    if (!domain.length) return null;
    return (
        <Helmet lang="pt-br" title={title}>
            <meta charset="utf-8" />
            <meta name="viewport" content="width=device-width, initial-scale=1" />
            <meta name="description" content={description} />
            <meta name="keywords" content={keywords} />
        </Helmet>
    );
}

export default DomainHead;
