import React from 'react';
import { Menu } from 'antd';
import { Link, useLocation } from 'react-router-dom';

import './style.scss';

function HeaderMenuDesktop() {
    const location = useLocation();

    return (
        <Menu mode="horizontal" selectable={false} selectedKeys={[location.pathname]} className="header-menu-options">
            <Menu.Item key="/">
                <Link to="/">Dicionário</Link>
            </Menu.Item>
            <Menu.Item key="/equipe">
                <Link to="/equipe">Equipe</Link>
            </Menu.Item>
            <Menu.Item key="/contato">
                <Link to="/contato">Contato</Link>
            </Menu.Item>
        </Menu>
    );
}

export default HeaderMenuDesktop;
