import React from 'react';
import { Helmet } from 'react-helmet';

function ContactHead() {
    const title = 'Contato';
    const description = 'Contato do Dicionário Enciclopédico do Novo Coronavírus';
    const keywords = 'contato, coronavírus, dicionário, enciclopédia, email, youtube, instagram';

    return (
        <Helmet lang="pt-br" title={title}>
            <meta charset="utf-8" />
            <meta name="viewport" content="width=device-width, initial-scale=1" />
            <meta name="description" content={description} />
            <meta name="keywords" content={keywords} />
        </Helmet>
    );
}

export default ContactHead;
