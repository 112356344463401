import * as axios from 'axios';

const apiUrl = process.env.REACT_APP_API_URL || 'https://lexicovid-19.herokuapp.com';

const Axios = (options) => {
    return axios({
        ...options,
        baseURL: apiUrl
    });
};
export default Axios;
