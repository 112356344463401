import React from 'react';
import { Layout } from 'antd';
import { GoogleOutlined, YoutubeFilled, InstagramFilled } from '@ant-design/icons';

import useIsMobile from '../../../hooks/use_is_mobile';

import Header from '../../organisms/header';
import Footer from '../../organisms/footer';
import ContactBox from '../../molecules/contact_box';

import './style.scss';
import ContactHead from '../../heads/contact';

const Content = Layout.Content;

function Contact() {
    const isMobile = useIsMobile();
    return (
        <>
            <ContactHead />
            <Layout className="page">
                <Header />
                <Content className="content">
                    <div className={isMobile ? 'column' : 'row justify-space-around inner-content'}>
                        <ContactBox
                            icon={<GoogleOutlined className="contact-icon google-icon" />}
                            title={'Gmail'}
                            description={'lexicovid19@gmail.com'}
                            url={'http://mailto:lexicovid19@gmail.com'}
                        />
                        <ContactBox
                            icon={<YoutubeFilled className="contact-icon youtube-icon" />}
                            title={'Youtube'}
                            description={'youtube.com/lexicovid19'}
                            url={'https://www.youtube.com/channel/UC05SzwN2IGAPSe9EzyFvMcg/featured'}
                        />
                        <ContactBox
                            icon={<InstagramFilled className="contact-icon instagram-icon" />}
                            title={'Instagram'}
                            description={'instagram.com/lexicovid19'}
                            url={'https://instagram.com/lexicovid19'}
                        />
                    </div>
                </Content>
                <Footer />
            </Layout>
        </>
    );
}

export default Contact;
