import React from 'react';
import { Typography } from 'antd';

import './style.scss';

function InfoTitle({ text, color = 'primary-color' }) {
    return (
        <div className="column align-center ">
            <Typography className="title">{text}</Typography>
            <div className={`info-title-underline ${color}`} />
        </div>
    );
}

export default InfoTitle;
