import React from 'react';
import { Typography } from 'antd';

import useIsMobile from '../../../hooks/use_is_mobile';

import './style.scss';

function TeamMember({ image, name, description, longDescription }) {
    const isMobile = useIsMobile();
    return isMobile ? (
        <div className="column align-center small-padding">
            <img src={image} alt="_PHOTO" className="team-member-image" />
            <Typography className="text-align-center subtitle">{name}</Typography>
            <Typography className="text-align-center">{description}</Typography>
        </div>
    ) : (
        <div className="row justify-center centered-container">
            <img src={image} alt="_PHOTO" className="team-member-image" />
            <div className="column left-spacing-4">
                <Typography className="subtitle text-align-left">{name}</Typography>
                <Typography className="text-align-left">{description}</Typography>
                <Typography className="top-spacing-2 text-align-left">{longDescription}</Typography>
            </div>
        </div>
    );
}

export default TeamMember;
