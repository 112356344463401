import Axios from '../services/api';
class ScenarioApi {
    static async list() {
        return await Axios({
            method: 'get',
            url: '/scenarios'
        });
    }
    static async get(slug) {
        return await Axios({
            method: 'get',
            url: `/scenarios/${slug}`
            // params: { slug }
        });
    }
}
export default ScenarioApi;
