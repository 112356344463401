import React, { useState, useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { Layout } from 'antd';

import useIsMobile from '../../../hooks/use_is_mobile';
import DomainApi from '../../../api/DomainApi';
import * as factories from '../../../factories';
import { SCENARIO } from '../../../constants/types';

import Header from '../../organisms/header';
import Footer from '../../organisms/footer';
import SectionTitle from '../../molecules/section_title';
import WordList from '../../molecules/word_list';
import Infographic from '../../molecules/infographic';
import TextDescription from '../../atoms/text_description';
import KnowMoreSection from '../../organisms/know_more_section';
import useVideoLink from '../../../hooks/use_video_link';

import './style.scss';
import DomainHead from '../../heads/domain';

const Content = Layout.Content;

function Domain() {
    const isMobile = useIsMobile();
    let { slug } = useParams();

    const [domain, setDomain] = useState([factories.Domain()]);
    const [links, setLinks] = useState([]);

    const infographicIsVisible = useMemo(
        () => domain && domain.horizontalInfographicUrl && domain.verticalInfographicUrl,
        [domain]
    );

    useEffect(() => {
        const fetchScenarios = async () => {
            const response = await DomainApi.get(slug);
            setDomain(response.data.data);
        };

        fetchScenarios();
    }, [slug]);

    useEffect(() => {
        const newLinks = [];
        if (domain && domain.articleName && domain.articleUrl)
            newLinks.push({ description: domain.articleName, url: domain.articleUrl });
        if (domain && domain.paperName && domain.paperUrl)
            newLinks.push({ description: domain.paperName, url: domain.paperUrl });
        setLinks(newLinks);
    }, [domain]);

    const videoLink = useVideoLink(domain.videoUrl);

    const youtubeVideo = isMobile ? (
        <iframe
            title="mobile"
            type="text/html"
            className="top-spacing-4"
            src={videoLink}
            width="280"
            frameBorder="157.5"
            allowFullScreen
        />
    ) : (
        <iframe
            title="desktop"
            className="left-spacing-4"
            type="text/html"
            src={videoLink}
            width="560"
            height="315"
            frameBorder="0"
            allowFullScreen
        />
    );

    return (
        <>
            <DomainHead
                domain={domain && domain.wordForm}
                definition={domain && domain.definition}
                scenarios={domain && domain.scenarios}
            />
            <Layout className="page">
                <Header />
                <Content className="content">
                    <SectionTitle selectedDomain={domain} tag="Domínio" />
                    {/* description */}

                    <TextDescription>{domain && domain.definition}</TextDescription>
                    {isMobile ? (
                        <WordList
                            listType={SCENARIO}
                            className="top-spacing-4"
                            color="secondary"
                            title="Cenários"
                            list={domain.scenarios}
                            domainSlug={slug}
                        />
                    ) : (
                        <div className="row flex-start full-width content-side-padding top-spacing-4">
                            <WordList
                                listType={SCENARIO}
                                color="secondary"
                                title="Cenários"
                                list={domain && domain.scenarios}
                                domainSlug={slug}
                            />
                            {domain && domain.videoUrl && youtubeVideo}
                        </div>
                    )}
                    {infographicIsVisible && (
                        <div className="full-width top-spacing-8">
                            <Infographic
                                className="top-spacing-8 full-width"
                                isMobile={isMobile}
                                horizontalSrc={domain && domain.horizontalInfographicUrl}
                                verticalSrc={domain && domain.verticalInfographicUrl}
                                src={domain.infographic}
                            />
                        </div>
                    )}

                    {domain && (
                        <div className="full-width">
                            <KnowMoreSection links={links} iconColor="red" isMobile={isMobile} />
                        </div>
                    )}

                    {/* mobile youtube video */}
                    {domain && domain.videoUrl && isMobile && youtubeVideo}
                </Content>
                <Footer />
            </Layout>
        </>
    );
}

export default Domain;
