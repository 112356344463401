import React from 'react';
import { Input } from 'antd';
import { useHistory } from 'react-router-dom';

import './style.scss';

import slugger from '../../../utils/slugger';
const Search = Input.Search;

function SearchBar() {
    const history = useHistory();
    const handleSearch = (input) => history.push(`/busca/${slugger(input)}`);
    return (
        <div className="search-bar-container">
            <Search placeholder="Buscar palavra" enterButton="Buscar" size="large" onSearch={handleSearch} />
        </div>
    );
}

export default SearchBar;
