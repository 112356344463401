import React from 'react';
import { Link } from 'react-router-dom';

import './style.scss';

function AppTitle() {
    return (
        <Link to="/">
            <div className="row app-logo-container">
                <img
                    src={`${process.env.PUBLIC_URL}/assets/images/lexicovid_logo.svg`}
                    alt="lexicovid_logo"
                    className="app-logo"
                />
            </div>
        </Link>
    );
}

export default AppTitle;
