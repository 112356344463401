import React from 'react';
import { Switch, Route } from 'react-router-dom';
import Home from './components/pages/home';
import Domain from './components/pages/domain';
import Scenario from './components/pages/scenario';
import Word from './components/pages/word';
import Team from './components/pages/team';
import Contact from './components/pages/contact';
import Search from './components/pages/search';
import Error404 from './components/pages/error_404';

function Routes() {
    return (
        <Switch>
            <Route exact path="/" component={Home} />
            <Route exact path="/dominio/:slug" component={Domain} />
            <Route exact path="/cenario/:slug" component={Scenario} />
            <Route exact path="/palavra/:slug" component={Word} />
            <Route exact path="/busca" component={Search} />
            <Route exact path="/busca/:userInput" component={Search} />
            <Route exact path="/equipe" component={Team} />
            <Route exact path="/contato" component={Contact} />
            <Route exact path="/error/404" component={Error404} />
        </Switch>
    );
}

export default Routes;
